/**
* Generated automatically at built-time (2025-03-03T14:43:51.646Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "buitenkeukens-voor-spanje",templateKey: "sites/107-cd7aefcb-9875-42c7-8adc-0d1082adc9e0"};
/**
* Generated automatically at built-time (2025-03-03T14:43:51.685Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-nordic-line",templateKey: "sites/107-bf60a5b1-ea75-45d6-a3b5-9fb26fd2c76f"};